.infoBox {
  cursor: pointer;
  flex: 1;
}

.infoBox:not(:last-child) {
  margin-right: 16px;
}

.infoBox--selected {
  border-top: 10px solid #98c379;
}

.infoBox--red {
  border-color: #de6b74;
}

.infoBox__cases--green {
  color: #98c379 !important;
}

.infoBox__cases {
  color: #de6b74;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.infoBox__total {
  color: #abb2bf;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  margin-top: 15px !important;
}
