.table {
  margin-top: 20px;
  overflow: scroll;
  height: 400px;
}

.table tr {
  display: flex;
  justify-content: space-between;
}

.table td {
  padding: 0.4rem;
}

.table tr:nth-of-type(odd) {
  background-color: #ededed;
}
