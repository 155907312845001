* {
  margin: 0;
}

body {
  background-color: #282c34;
  color: #abb2bf;
}

.app {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.app__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.app_dropdown {
  background-color: #ebeaef;
  border-radius: 4px;
}

.app__left {
  flex: 0.9;
}

.app__right {
  display: flex;
  flex-direction: column;
}

.app__right .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app__graph {
  flex-grow: 1;
}

.app__graphTitle {
  margin: 20px 0 10px 0;
}

.app__stats {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }
}
